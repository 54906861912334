import React, { useEffect } from 'react';
import { Checkbox, Form, Input, Modal } from '@arco-design/web-react';
import { AddUser, UpdateUser, GetUser } from '@/api/user/user';
import Password from '@arco-design/web-react/es/Input/password';
import PermissionWrapper from '@/components/PermissionWrapper';

const { useForm } = Form;
const roles = [
  { label: '操作员', value: 'worker' },
  { label: '管理员', value: 'admin' },
];

function DiaForm(props: {
  visible: boolean;
  data: API.User;
  onClose: () => void;
  afterSave: (formData: Partial<API.User>) => void;
}) {
  let { visible } = props;
  const [form] = useForm<API.User>();

  useEffect(() => {
    if (props.data && props.data.id) {
      GetUser(props.data.id).then((res) => {
        console.log(res);
        form.setFieldsValue(res.data);
      });
    } else {
      form.resetFields();
    }
    return;
  }, [visible]);

  return (
    <Modal
      title={props.data && props.data.id ? '修改' : '新增'}
      visible={visible}
      onCancel={() => {
        visible = false;
        props.onClose();
      }}
      onOk={async () => {
        visible = false;
        const values = await form.validate();
        const data: API.User = {
          id: undefined,
          userName: values.userName,
          name: values.name,
          tel: values.tel,
          email: values.email,
          password: values.password,
          roles: values.roles,
        };

        let res;
        if (props.data && props.data.id) {
          data.id = props.data.id;
          res = await UpdateUser(data.id, data);
        } else {
          res = await AddUser(data);
        }
        console.log(form.getFieldsValue());
        props.afterSave(form.getFieldsValue());
      }}
    >
      <Form form={form} labelAlign="left">
        <Form.Item
          label="姓名"
          field="name"
          rules={[{ required: true, message: '请输入用户姓名' }]}
        >
          <Input placeholder="请输入姓名" allowClear />
        </Form.Item>
        <Form.Item
          label="用户名"
          field="userName"
          rules={[{ required: true, message: '请输入用户名' }]}
        >
          <Input placeholder="请输入用户名" allowClear />
        </Form.Item>
        <Form.Item label="联系方式" field="tel">
          <Input placeholder="请输入联系方式" allowClear />
        </Form.Item>
        <Form.Item label="邮箱" field="email">
          <Input placeholder="请输入邮箱" allowClear />
        </Form.Item>
        {props.data && props.data.id ? (
          <></>
        ) : (
          <Form.Item
            label="密码"
            field="password"
            rules={[
              { required: true, message: '请输入密码' },
              { minLength: 8, message: '密码长度不应少于8位' },
            ]}
          >
            <Input.Password placeholder="请输入密码" allowClear />
          </Form.Item>
        )}
        <PermissionWrapper
          requiredPermissions={[
            { resource: 'userInfo', actions: ['setRoles'] },
          ]}
        >
          <Form.Item
            label="角色"
            field="roles"
            rules={[{ required: true, message: '请选择用户角色' }]}
          >
            <Checkbox.Group options={roles} />
          </Form.Item>
        </PermissionWrapper>
      </Form>
    </Modal>
  );
}

export default DiaForm;
