import axios, { Axios } from 'axios';
import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Route } from 'react-router-dom';
import qs from 'query-string';
import merge from 'lodash/merge';
import { Message, Notification } from '@arco-design/web-react';
import FactMultiPie from './components/Chart/fact-multi-pie';
import { IconUnderline } from '@arco-design/web-react/icon';

const codeVerificationArray = [0, 200, 2000];

const showMessage = (
  msg: string,
  showType = 0,
  success = true,
  errorCode = 1001,
  errorMsg = '',
  duration = 3000,
  onClose?
) => {
  const showCfg = {
    content: msg,
    duration: duration,
    onClose: onClose,
  };
  if (success === false) {
    console.log(`系统异常：${msg}\n${errorMsg}`);
    if (msg === '' && errorMsg !== '') {
      showCfg.content = errorMsg;
    }
    if (msg !== '') {
      showCfg.content = `操作失败[${errorCode}]:${showCfg.content}`;
    }
  }
  switch (showType) {
    case 0:
      return;
    case 1:
      Message.warning(showCfg);
      break;
    case 2:
      Message.error(showCfg);
      break;
    case 3:
      Notification.warning(showCfg);
      break;
    case 4:
      Notification.error(showCfg);
      break;
    case 5:
      Message.success(showCfg);
      break;
  }
};

/**
 * @description 处理code异常
 * @param {*} code
 * @param {*} msg
 */
const handleCode = (code, errorCode, msg, errorMsg = '', showType = 1) => {
  switch (code) {
    case 402:
      //   Vue.prototype.$message(msg || `后端接口${code}异常`, 'error');
      location.reload();
      break;
    case 401:
      console.log(window.location.href);
      localStorage.setItem('userStatus', 'logout');
      showMessage(
        msg + '[请重新登录]',
        showType,
        false,
        errorCode,
        errorMsg,
        3000,
        () => {
          window.location.href =
            '/login?href=' + encodeURIComponent(window.location.href);
        }
      );
      break;
    default:
      //   Vue.prototype.$message(msg || `后端接口${code}异常`, 'error');
      showMessage(msg, showType, false, errorCode, errorMsg);
      break;
  }
};

const http = axios.create({
  timeout: 1000 * 70,
  withCredentials: true,
  baseURL: '/canaan',
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
  },
  handleRes: true,
});

/**
 * 请求拦截
 */
http.interceptors.request.use(
  (config) => {
    // config.headers['Authorization'] = Vue.cookie.get('token') // 请求头带上token
    config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('token');
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * 响应拦截
 */
http.interceptors.response.use(
  (response) => {
    const { data, config } = response;
    const handleRes = config.handleRes;
    const { code } = data;
    // 操作正常Code数组
    if (data.showType !== undefined && data.showType !== 0) {
      showMessage(data.userMessage, data.showType);
    }
    if (data.success == false) {
      return Promise.reject(handleRes ? data : response);
    }
    return handleRes ? data : response;
    // return data;
  },
  (error) => {
    const { response, message } = error;
    console.log('in errorr', error);
    if (
      error.response &&
      error.response.data != undefined &&
      error.response.data.showType != undefined
    ) {
      const { status, data } = response;
      handleCode(
        status,
        data.errorCode,
        data.userMessage,
        data.errorMessage,
        data.showType || 1
      );
      return Promise.reject(error);
    } else {
      let { message } = error;
      if (message === 'Network Error') {
        message = '后端接口连接异常';
      }
      if (message.includes('timeout')) {
        message = '后端接口请求超时';
      }
      if (message.includes('Request failed with status code')) {
        const code = message.substr(message.length - 3);
        message = '后端接口' + code + '异常';
      }
      Message.error(message);
      return Promise.reject(error);
    }
  }
);

/**
 * 判断响应是否合法
 * @param {*} res 响应结果
 */
const checkSuccess = (res) => {
  return res && codeVerificationArray.includes(res.code);
};

/**
 * 请求成功后，根据code，进行message提示
 * @param {*} data 返回结果
 * @param {*} onSuccess 成功后的操作
 * @param {*} onFailure 失败后的操作
 */
const handleSuccess = (
  data,
  onSuccess,
  onFailure,
  showSuccessMsg = false,
  showFailMsg = true
) => {
  if (checkSuccess(data)) {
    if (showSuccessMsg) {
      //   Vue.prototype.$message({
      //     message: data.userMsg || '操作成功',
      //     type: 'success',
      //     duration: 1500,
      //     onClose: () => {
      //       if (onSuccess) {
      //         onSuccess(data);
      //       }
      //     },
      //   });
    } else {
      if (onSuccess) {
        onSuccess(data);
      }
    }
    return true;
  } else {
    if (showFailMsg) {
      //   Vue.prototype.$message({
      //     message: data.userMsg || '操作失败',
      //     type: 'error',
      //     duration: 1500,
      //     onClose: () => {
      //       if (onFailure) {
      //         onFailure(data);
      //       }
      //     },
      //   });
    } else {
      if (onFailure) {
        onFailure(data);
      }
    }
    return false;
  }
};

/**
 * 请求地址处理
 * @param {*} actionName action方法名称
 */
const adornUrl = (actionName, check = false) => {
  if (!check) {
    return actionName;
  } else {
    return actionName;
    // return (
    //   (process.env.NODE_ENV !== 'production' && process.env.OPEN_PROXY
    //     ? '/proxyApi/'
    //     : window.SITE_CONFIG.baseUrl) + actionName
    // );
  }
};
// http.adornUrl = (actionName) => {
// 非生产环境 && 开启代理, 接口前缀统一使用[/proxyApi/]前缀做代理拦截!
// return (
//   (process.env.NODE_ENV !== 'production' && process.env.OPEN_PROXY
//     ? '/proxyApi/'
//     : window.SITE_CONFIG.baseUrl) + actionName
// )
// }

/**
 * get请求参数处理
 * @param {*} params 参数对象
 * @param {*} openDefaultParams 是否开启默认参数?
 */
const adornParams = (params = {}, openDefaultParams = true) => {
  const defaults = {
    t: new Date().getTime(),
  };
  return openDefaultParams ? merge(defaults, params) : params;
};

/**
 * post请求数据处理
 * @param {*} data 数据对象
 * @param {*} openDefaultData 是否开启默认数据?
 * @param {*} contentType 数据格式
 *  json: 'application/json; charset=utf-8'
 *  form: 'application/x-www-form-urlencoded; charset=utf-8'
 */
const adornData = (data = {}, openDefaultData = true, contentType = 'json') => {
  const defaults = {
    t: new Date().getTime(),
  };
  data = openDefaultData ? merge(defaults, data) : data;
  return contentType === 'json' ? JSON.stringify(data) : qs.stringify(data);
};

export const downloadFile = (res, defaultName?: string) => {
  const contentDisposition = res.headers['content-disposition'];
  let fileName = defaultName ?? '导出文件';
  if (!defaultName) {
    if (contentDisposition.search('filename=') > 0) {
      fileName = decodeURI(
        escape(
          contentDisposition.slice(
            contentDisposition.search('filename=') + 'filename='.length
          )
        )
      ).replaceAll('"', '');
    }
  }
  const objectUrl = URL.createObjectURL(
    new Blob([res.data] as unknown as Blob[])
  );
  const link = document.createElement('a');
  link.download = decodeURI(fileName);
  link.href = objectUrl;
  link.click();
};

export const getHeaders = () => {
  return {
    Authorization: 'Bearer ' + localStorage.getItem('token'),
  };
};
export const getUrl = (url) => {
  return `/canaan${url}`;
};

export default http;
