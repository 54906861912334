const i18n = {
  'zh-CN': {
    'menu.dashboard': '仪表盘',
    'menu.list': '列表页',
    'menu.result': '结果页',
    'menu.exception': '异常页',
    'menu.form': '表单页',
    'menu.profile': '详情页',
    'menu.visualization': '数据可视化',
    'menu.user': '个人中心',
    'menu.exception.403': '403',
    'menu.exception.404': '404',
    'menu.exception.500': '500',
    'menu.profile.basic': '基础详情页',
    'menu.list.cardList': '卡片列表',
    'menu.visualization.dataAnalysis': '分析页',
    'menu.result.error': '失败页',
    'menu.form.group': '分组表单',
    'menu.dashboard.monitor': '实时监控',
    'menu.visualization.multiDimensionDataAnalysis': '多维数据分析',
    'menu.list.searchTable': '查询表格',
    'menu.form.step': '分步表单',
    'menu.result.success': '成功页',
    'menu.user.info': '用户信息',
    'menu.user.setting': '用户设置',
    'menu.user.rePassword': '重置密码',
    'menu.user.switchRoles': '切换角色',
    'menu.user.role.admin': '管理员',
    'menu.user.role.user': '普通用户',
    'menu.dashboard.workplace': '工作台',
    'navbar.logout': '退出登录',
    'settings.title': '页面配置',
    'settings.themeColor': '主题色',
    'settings.content': '内容区域',
    'settings.navbar': '导航栏',
    'settings.menuWidth': '菜单宽度 (px)',
    'settings.navbar.theme.toLight': '点击切换为亮色模式',
    'settings.navbar.theme.toDark': '点击切换为暗黑模式',
    'settings.menu': '菜单栏',
    'settings.footer': '底部',
    'settings.otherSettings': '其他设置',
    'settings.colorWeek': '色弱模式',
    'settings.alertContent':
      '配置之后仅是临时生效，要想真正作用于项目，点击下方的 "复制配置" 按钮，将配置替换到 settings.json 中即可。',
    'settings.copySettings': '复制配置',
    'settings.copySettings.message':
      '复制成功，请粘贴到 src/settings.json 文件中',
    'settings.close': '关闭',
    'settings.color.tooltip':
      '根据主题颜色生成的 10 个梯度色（将配置复制到项目中，主题色才能对亮色 / 暗黑模式同时生效）',
    'message.tab.title.message': '消息',
    'message.tab.title.notice': '通知',
    'message.tab.title.todo': '待办',
    'message.allRead': '全部已读',
    'message.seeMore': '查看更多',
    'message.empty': '清空',
    'message.empty.tips': '暂无内容',
    'message.lang.tips': '语言切换至 ',
    'navbar.search.placeholder': '输入内容查询',
  },
};

export default i18n;
