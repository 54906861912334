import http from '@/http';
import getRFC from '@/utils/time';
import qs from 'query-string';

const urlPrefix = '/api/web/pda/pickInfo';

export const PickInfoApi = {
  getReq: function (req: API.PickInfoQuery) {
    const r = { ...req };
    r.createdAtStart = getRFC(r.createdAtStart);
    r.createdAtEnd = getRFC(r.createdAtEnd);
    return r;
  },
  page: function (req: API.PickInfoQuery) {
    return http.post<API.PageData<API.PickInfo[]>>(
      `${urlPrefix}/page`,
      this.getReq(req)
    );
  },
  callExport: function (req: API.PickInfoQuery) {
    return http(`${urlPrefix}/callExport`, {
      data: this.getReq(req),
      method: 'POST',
      responseType: 'blob',
      handleRes: false,
    });
  },
  viewDetail: function (id: number) {
    return http.post<API.PickDetail[]>(`${urlPrefix}/viewDetail/${id}`);
  },
  viewHandOver: function (id: number) {
    return http.post<API.PickDetailHandOver[]>(
      `${urlPrefix}/viewHandOver/${id}`
    );
  },
};

export default PickInfoApi;
