import React, { useContext, useEffect, useState } from 'react';
import {
  Tooltip,
  Input,
  Avatar,
  Select,
  Dropdown,
  Menu,
  Divider,
  Message,
  Button,
} from '@arco-design/web-react';
import {
  IconLanguage,
  IconNotification,
  IconSunFill,
  IconMoonFill,
  IconUser,
  IconSettings,
  IconPoweroff,
  IconExperiment,
  IconDashboard,
  IconInteraction,
  IconTag,
  IconLoading,
  IconReply,
  IconRefresh,
} from '@arco-design/web-react/icon';
import { useSelector, useDispatch } from 'react-redux';
import { GlobalState } from '@/store';
import { GlobalContext } from '@/context';
import useLocale from '@/utils/useLocale';
import AvatarSvg from '@/assets/male.svg';
// import Logo from '@/assets/logo.svg';
// import MessageBox from '@/components/MessageBox';
import IconButton from './IconButton';
import Settings from '../Settings';
import styles from './style/index.module.less';
import defaultLocale from '@/locale';
import useStorage from '@/utils/useStorage';
import { generatePermission } from '@/routes';
import UserSettingForm from '@/pages/user/userList/form';
import RePasswordForm from '@/pages/user/userList/rePasswordForm';

function Navbar({ show }: { show: boolean }) {
  const t = useLocale();
  const { userInfo, userLoading } = useSelector((state: GlobalState) => state);
  const dispatch = useDispatch();

  const [_, setUserStatus] = useStorage('userStatus');
  const [role, setRole] = useStorage('userRole', 'admin');

  const { theme, setTheme } = useContext(GlobalContext);
  const [settingFormVis, setSettingFormVis] = useState<boolean>(false);
  const [rePasswordFormVis, setRePasswordFormVis] = useState<boolean>(false);

  function logout() {
    setUserStatus('logout');
    window.location.href = '/login';
  }

  function onMenuItemClick(key) {
    if (key === 'logout') {
      logout();
    } else if (key === 'rePassword') {
      setRePasswordFormVis(true);
    } else if (key === 'setting') {
      setSettingFormVis(true);
    }
  }

  // useEffect(() => {
  //   dispatch({
  //     type: 'update-userInfo',
  //     payload: {
  //       userInfo: {
  //         ...userInfo,
  //         permissions: generatePermission(role),
  //       },
  //     },
  //   });
  // }, [role]);

  // if (!show) {
  //   return (
  //     <div className={styles['fixed-settings']}>
  //       <Settings
  //         trigger={
  //           <Button icon={<IconSettings />} type="primary" size="large" />
  //         }
  //       />
  //     </div>
  //   );
  // }

  const handleChangeRole = () => {
    const newRole = role === 'admin' ? 'user' : 'admin';
    setRole(newRole);
  };

  const droplist = (
    <Menu onClickMenuItem={onMenuItemClick}>
      {/* <Menu.SubMenu
        key="role"
        title={
          <>
            <IconUser className={styles['dropdown-icon']} />
            <span className={styles['user-role']}>
              {role === 'admin'
                ? t['menu.user.role.admin']
                : t['menu.user.role.user']}
            </span>
          </>
        }
      >
        <Menu.Item onClick={handleChangeRole} key="switch role">
          <IconTag className={styles['dropdown-icon']} />
          {t['menu.user.switchRoles']}
        </Menu.Item>
      </Menu.SubMenu> */}
      <Menu.Item key="setting">
        <IconSettings className={styles['dropdown-icon']} />
        {t['menu.user.setting']}
      </Menu.Item>
      <Menu.Item key="rePassword">
        <IconRefresh className={styles['dropdown-icon']} />
        {t['menu.user.rePassword']}
      </Menu.Item>
      {/* <Menu.SubMenu
        key="more"
        title={
          <div style={{ width: 80 }}>
            <IconExperiment className={styles['dropdown-icon']} />
            {t['message.seeMore']}
          </div>
        }
      >
        <Menu.Item key="workplace">
          <IconDashboard className={styles['dropdown-icon']} />
          {t['menu.dashboard.workplace']}
        </Menu.Item>
        <Menu.Item key="card list">
          <IconInteraction className={styles['dropdown-icon']} />
          {t['menu.list.cardList']}
        </Menu.Item>
      </Menu.SubMenu> */}

      <Divider style={{ margin: '4px 0' }} />
      <Menu.Item key="logout">
        <IconPoweroff className={styles['dropdown-icon']} />
        {t['navbar.logout']}
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={styles.navbar}>
      <div className={styles.left}>
        <div className={styles.logo}>
          {/* <Logo /> */}
          <div className={styles['logo-name']}>ISH WMS</div>
        </div>
      </div>
      <ul className={styles.right}>
        {/* <li>
          <Input.Search
            className={styles.round}
            placeholder={t['navbar.search.placeholder']}
          />
        </li> */}
        {/* <li>
          <Select
            triggerElement={<IconButton icon={<IconLanguage />} />}
            options={[
              { label: '中文', value: 'zh-CN' },
              { label: 'English', value: 'en-US' },
            ]}
            value={lang}
            triggerProps={{
              autoAlignPopupWidth: false,
              autoAlignPopupMinWidth: true,
              position: 'br',
            }}
            trigger="hover"
            onChange={(value) => {
              setLang(value);
              const nextLang = defaultLocale[value];
              Message.info(`${nextLang['message.lang.tips']}${value}`);
            }}
          />
        </li> */}
        {/* <li>
          <MessageBox>
            <IconButton icon={<IconNotification />} />
          </MessageBox>
        </li> */}
        <li>
          <Tooltip
            content={
              theme === 'light'
                ? t['settings.navbar.theme.toDark']
                : t['settings.navbar.theme.toLight']
            }
          >
            <IconButton
              icon={theme !== 'dark' ? <IconMoonFill /> : <IconSunFill />}
              onClick={() => setTheme(theme === 'light' ? 'dark' : 'light')}
            />
          </Tooltip>
        </li>
        {/* <Settings /> */}
        {userInfo && (
          <li>
            <Dropdown droplist={droplist} position="br" disabled={userLoading}>
              <Avatar size={32} style={{ cursor: 'pointer' }}>
                {userLoading ? (
                  <IconLoading />
                ) : (
                  <AvatarSvg
                    style={{ borderRadius: 'var(--border-radius-circle)' }}
                  />
                )}
              </Avatar>
            </Dropdown>
          </li>
        )}
      </ul>

      <UserSettingForm
        visible={settingFormVis}
        data={{ id: userInfo.id }}
        onClose={function (): void {
          // setFormData(undefined);
          setSettingFormVis(false);
        }}
        afterSave={function (): void {
          setSettingFormVis(false);
          // fetchData();
          return;
        }}
      />
      <RePasswordForm
        visible={rePasswordFormVis}
        id={userInfo.id}
        onClose={() => {
          setRePasswordFormVis(false);
        }}
        afterSave={() => {
          setRePasswordFormVis(false);
          Message.success({
            content: '密码重置成功，请重新登录!',
            duration: 1500,
            onClose: () => {
              logout();
            },
          });
        }}
      />
    </div>
  );
}

export default Navbar;
