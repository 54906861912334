import React from 'react';
import { Carousel } from '@arco-design/web-react';
import useLocale from '@/utils/useLocale';
import locale from './locale';
import styles from './style/index.module.less';

export default function LoginBanner() {
  const t = useLocale(locale);
  const data = [
    {
      slogan: '',
      subSlogan: '',
      image:
        'https://nwzimg.wezhan.cn/contents/sitefiles2054/10274051/images/30681019.jpg',
    },
    {
      slogan: '',
      subSlogan: '',
      image:
        'https://nwzimg.wezhan.cn/contents/sitefiles2054/10274051/images/30681027.jpg',
    },
    {
      slogan: '',
      subSlogan: '',
      image:
        'https://nwzimg.wezhan.cn/contents/sitefiles2054/10274051/images/30681275.jpg',
    },
    {
      slogan: '',
      subSlogan: '',
      image:
        'https://nwzimg.wezhan.cn/contents/sitefiles2054/10274051/images/30681017.jpg',
    },
  ];
  return (
    <Carousel className={styles.carousel} animation="fade">
      {data.map((item, index) => (
        <div key={`${index}`}>
          <div className={styles['carousel-item']}>
            <div className={styles['carousel-title']}>{item.slogan}</div>
            <div className={styles['carousel-sub-title']}>{item.subSlogan}</div>
            <img
              alt="banner-image"
              className={styles['carousel-image']}
              src={item.image}
            />
          </div>
        </div>
      ))}
    </Carousel>
  );
}
