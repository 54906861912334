import React, { useState, useEffect, useMemo, RefObject } from 'react';
import { useSelector } from 'react-redux';
import {
  Table,
  Card,
  PaginationProps,
  Button,
  Space,
  Typography,
  Descriptions,
  Divider,
  Grid,
} from '@arco-design/web-react';
import styles from './style/printTable.module.less';
import {
  IconDownload,
  IconOriginalSize,
  IconPlus,
} from '@arco-design/web-react/icon';
import { QRCodeSVG } from 'qrcode.react';
import { center, degreesToRadians } from '@turf/turf';
import { ColumnProps } from '@arco-design/web-react/es/Table';
import PickInfoApi from '@/api/pda/pickInfo';
import dayjs from 'dayjs';
import { GlobalState } from '@/store';

const { Title, Text } = Typography;
const { Row, Col } = Grid;

const columns: ColumnProps[] = [
  {
    title: '项',
    render(col, item, index) {
      return index + 1;
    },
    width: 40,
  },
  {
    title: '物料编号',
    dataIndex: 'itemNumber',
    width: 110,
  },
  {
    title: '物料描述',
    dataIndex: 'itemDesc',
    width: 165,
    ellipsis: true,
    // render(col, item, index) {
    //   return <Text ellipsis={{ rows: 2 }}>{col}</Text>;
    // },
  },
  {
    title: '包',
    dataIndex: 'count',
    align: 'center',
    width: 25,
  },
  {
    title: '批次',
    dataIndex: 'lotNo',
    width: 110,
  },
  {
    title: '库位',
    dataIndex: 'lot07',
    width: 70,
    render: (col) => (col ? '102/' + col : ''),
  },
  {
    title: '数量',
    dataIndex: 'recQty',
    // align: 'right',
    width: 60,
  },
  {
    title: '供应商代码',
    dataIndex: 'vendorCode',
    // align: 'right',
    width: 75,
  },
];

const PrintTable = React.forwardRef((props: { infoId: number }, ref) => {
  const { userInfo } = useSelector((state: GlobalState) => state);
  const [data, setData] = useState<API.PickDetailHandOver[]>([]);
  useEffect(() => {
    if (!props.infoId) {
      setData([]);
      return;
    }
    PickInfoApi.viewHandOver(props.infoId).then((res) => {
      console.log(res);
      const d = res.data.sort((a, b) => {
        return (a.itemNumber + a.lotNo).localeCompare(b.itemNumber + b.lotNo);
        // return a.lotNo.localeCompare(b.lotNo);
      });
      const merge = [];
      let mergeI = 0;

      if (d.length > 0) {
        d[0].count = 1;
        merge.push(d[0]);
        window.document.title = '交接单:' + merge[0].ishNumber;
      }

      for (let i = 1; i < d.length; i++) {
        const e = d[i];
        const m = merge[mergeI];
        if (m.itemNumber == e.itemNumber && m.lotNo == e.lotNo) {
          m.count++;
          m.recQty += e.recQty;
        } else {
          e.count = 1;
          merge.push(e);
          mergeI++;
        }
      }
      console.log(merge);
      setData(merge);
    });
  }, [props.infoId]);

  return (
    <div className={styles['print-bg']}>
      <Card ref={ref} className={styles['print-content']}>
        <div className={styles['print-title']}>
          <Title heading={3}>北京综合信兴物流有限公司</Title>
          <Title heading={5}>出货交接单</Title>
        </div>

        <div className={styles['print-desc']}>
          <Descriptions
            // className={styles['print-desc-table']}
            // border
            column={3}
            colon=":"
            labelStyle={{ textAlign: 'right' }}
            valueStyle={{ width: 360 }}
            size="small"
            data={[
              {
                label: '交接单号',
                span: 3,
                value: data.length > 0 ? data[0].ishNumber : '',
              },
              { label: 'From', value: 'ISH仓库' },
              { label: 'To', value: '嘉楠' },
              { label: '库管员', value: userInfo.name },
              {
                label: '日期',
                value: dayjs(new Date()).format('YYYY年MM月DD日'),
              },
              {
                label: '时间',
                value: dayjs(new Date()).format('HH:mm:ss'),
              },
            ]}
          ></Descriptions>
        </div>
        {/* <Divider /> */}
        <Table
          border
          className={styles['print-table']}
          columns={columns}
          rowKey={'id'}
          data={data}
          pagination={false}
          size="small"
          summary={(currentData) => (
            <Table.Summary>
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={4} style={{ textAlign: 'right' }}>
                  <Typography.Text bold>不同批次:</Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell colSpan={1}>
                  {currentData.reduce((prev, next, i, array) => {
                    if (i > 0) {
                      if (array[i].lotNo != array[i - 1].lotNo) {
                        return prev + 1;
                      } else {
                        return prev;
                      }
                    } else {
                      return 1;
                    }
                  }, 0)}
                </Table.Summary.Cell>
                <Table.Summary.Cell colSpan={1} style={{ textAlign: 'right' }}>
                  <Typography.Text bold>总数:</Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell colSpan={2}>
                  {currentData.reduce((prev, next) => prev + next.recQty, 0)}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
            // <Table.Summary fixed="bottom">{summary(currentData)}</Table.Summary>
          )}
        />
        {/* <Divider /> */}
        <Space style={{ paddingTop: 20 }}>
          <Text>制单员：</Text>
          <div style={{ width: 200 }}>
            <Text>{userInfo.name}</Text>
          </div>
          {/* <div style={{ paddingRight: 80 }} /> */}
          <Text>制单员：</Text>
          <div style={{ paddingRight: 120 }} />
          <Text>运输司机：</Text>
          <div style={{ paddingRight: 120 }} />
          <Text>收货人：</Text>
        </Space>

        <Divider />
      </Card>
    </div>
  );
});

export default PrintTable;
