import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Table,
  Card,
  PaginationProps,
  Button,
  Space,
  Typography,
} from '@arco-design/web-react';
import styles from './style/index.module.less';
import PickInfoApi from '@/api/pda/pickInfo';
import qs from 'query-string';
import PickPrintTable from './printTable';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import getUrlParams from '@/utils/getUrlParams';
import { IconPrinter } from '@arco-design/web-react/icon';

function PickPrintPage(props) {
  const componentRef = useRef<any>();
  window.document.title = '出货拣货单';
  const params = getUrlParams();
  const [infoId, setInfoId] = useState<number>(parseInt(params['id']));

  return (
    <div>
      <ReactToPrint
        trigger={() => (
          <Button
            type="primary"
            icon={<IconPrinter fontSize={22} />}
            className={styles['top-button']}
            size="large"
            shape="round"
          >
            打印
          </Button>
        )}
        content={() => componentRef.current}
        pageStyle=".divider { break-after: always; }"
      />
      <PickPrintTable infoId={infoId} ref={componentRef} />
    </div>
  );
}

export default PickPrintPage;
