import http from '@/http';

const apiPrefix = '/api/web/user';

export async function Login(data: any) {
  return http(`/api/login`, {
    method: 'POST',
    data: data,
  });
}

export async function FetchUserInfo() {
  return http(`${apiPrefix}/userInfo`, { method: 'GET' });
}

export async function RePassword(id: number, oldPassword, newPassword: string) {
  return http(`${apiPrefix}/rePassword`, {
    method: 'POST',
    data: {
      id: id,
      password: oldPassword,
      newPassword: newPassword,
    },
  });
}

export async function PageUser(
  query: API.UserQuery,
  options?: { [key: string]: any }
) {
  return http(`${apiPrefix}/page`, {
    method: 'POST',
    data: query,
    ...(options || {}),
  });
}

export async function GetUser(id: number, options?: { [key: string]: any }) {
  return http(`${apiPrefix}/${id}`, {
    method: 'GET',
    ...(options || {}),
  });
}

export async function AddUser(
  body: API.User,
  options?: { [key: string]: any }
) {
  return http(`${apiPrefix}`, {
    method: 'POST',
    data: body,
    ...(options || {}),
  });
}

export async function UpdateUser(
  id: number,
  body: API.User,
  options?: { [key: string]: any }
) {
  return http(`${apiPrefix}/${id}`, {
    method: 'PUT',
    data: body,
    ...(options || {}),
  });
}

export async function DeleteUser(id: number, options?: { [key: string]: any }) {
  return http(`${apiPrefix}/${id}`, {
    method: 'DELETE',
    ...(options || {}),
  });
}
