const i18n = {
  'zh-CN': {
    'login.form.title': '登录 ISH - WMS',
    'login.form.userName.errMsg': '用户名不能为空',
    'login.form.password.errMsg': '密码不能为空',
    'login.form.login.errMsg': '登录出错，请刷新重试',
    'login.form.userName.placeholder': '用户名',
    'login.form.password.placeholder': '密码',
    'login.form.rememberPassword': '记住密码',
    'login.form.forgetPassword': '忘记密码',
    'login.form.login': '登录',
    // 'login.form.register': '注册账号',
    'login.banner.slogan1': '开箱即用的高质量模板',
    'login.banner.subSlogan1': '丰富的的页面模板，覆盖大多数典型业务场景',
    'login.banner.slogan2': '内置了常见问题的解决方案',
    'login.banner.subSlogan2': '国际化，路由配置，状态管理应有尽有',
    'login.banner.slogan3': '接入可视化增强工具AUX',
    'login.banner.subSlogan3': '实现灵活的区块式开发',
  },
};

export default i18n;
