import React, { useState, useEffect, useMemo, RefObject } from 'react';
import { useSelector } from 'react-redux';
import {
  Table,
  Card,
  PaginationProps,
  Button,
  Space,
  Typography,
  Descriptions,
} from '@arco-design/web-react';
import styles from './style/printTable.module.less';
import { IconDownload, IconPlus } from '@arco-design/web-react/icon';
import { QRCodeSVG } from 'qrcode.react';
import { center, degreesToRadians } from '@turf/turf';
import { ColumnProps } from '@arco-design/web-react/es/Table';
import { getData } from './mockData';
import PickInfoApi from '@/api/pda/pickInfo';
import dayjs from 'dayjs';
import { GlobalState } from '@/store';

const { Title } = Typography;

const columns: ColumnProps[] = [
  {
    title: '项',
    render(col, item, index) {
      return index + 1;
    },
    width: 40,
  },
  {
    title: 'ISH编号',
    dataIndex: 'ishNumber',
    width: 125,
  },
  {
    title: '行号',
    dataIndex: 'ishLineNumber',
    width: 35,
  },
  {
    title: '库位',
    dataIndex: 'lot07',
    width: 80,
    render: (col) => (col ? '102/' + col : ''),
  },
  {
    title: '储位',
    dataIndex: 'locNo',
    width: 80,
  },
  {
    title: '箱号',
    dataIndex: 'boxSn',
    width: 140,
  },
  {
    title: '物料编号',
    dataIndex: 'itemNumber',
    width: 125,
  },
  {
    title: 'ASN',
    dataIndex: 'asn',
    width: 100,
  },
  {
    title: '数量',
    dataIndex: 'qty',
    align: 'right',
    width: 60,
  },
];

const PickPrintTable = React.forwardRef((props: { infoId: number }, ref) => {
  const { userInfo } = useSelector((state: GlobalState) => state);
  const [data, setData] = useState<API.PickDetail[]>([]);
  const [lines, setLines] = useState<string[]>([]);
  useEffect(() => {
    if (!props.infoId) {
      setData([]);
      setLines([]);
      return;
    }
    PickInfoApi.viewDetail(props.infoId).then((res) => {
      setData(res.data);
      const lineMap = new Map();
      const ls = [];
      res.data.forEach((e) => {
        if (!lineMap[e.ishLineNumber]) {
          lineMap[e.ishLineNumber] = true;
          ls.push(e.ishLineNumber);
        }
      });
      setLines(ls);
    });
  }, [props.infoId]);

  return (
    <div className={styles['print-bg']}>
      <Card ref={ref} className={styles['print-content']}>
        <div className={styles['print-title']}>
          <Title heading={3}>北京综合信兴物流有限公司</Title>
          <Title heading={5}>出货拣货单</Title>
        </div>

        <div className={styles['print-desc']}>
          {/* <img></img> */}
          {data.length > 0 ? (
            <QRCodeSVG
              className={styles['print-desc-qr']}
              value={data[0].ishNumber}
              size={100}
            />
          ) : (
            <></>
          )}
          <Descriptions
            className={styles['print-desc-table']}
            border
            column={3}
            data={[
              {
                label: '叫料单号',
                value: data.length > 0 ? data[0].ishNumber : '',
              },
              { label: 'From', value: 'ISH仓库' },
              { label: 'To', value: '嘉楠' },
              { label: '库管员', value: userInfo.name },
              {
                label: '时间',
                value: dayjs(new Date()).format('YYYY年MM月DD日 HH:mm:ss'),
                span: 2,
              },
              { label: '行号', value: lines.sort().join(',') },
            ]}
          ></Descriptions>
        </div>
        <Title heading={6} style={{ textAlign: 'center', paddingTop: 20 }}>
          物料明细
        </Title>
        <Table
          className={styles['print-table']}
          columns={columns}
          rowKey={'id'}
          data={data}
          pagination={false}
          size="small"
          summary={(currentData) => (
            <Table.Summary>
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={8} style={{ textAlign: 'right' }}>
                  <Typography.Text bold>合计:</Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  {currentData.reduce((prev, next) => prev + next.qty, 0)}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
            // <Table.Summary fixed="bottom">{summary(currentData)}</Table.Summary>
          )}
        />
      </Card>
    </div>
  );
});

export default PickPrintTable;
