import React, { useEffect } from 'react';
import { Checkbox, Form, Input, Message, Modal } from '@arco-design/web-react';
import { RePassword } from '@/api/user/user';
import authentication from '@/utils/authentication';
import { GlobalState } from '@/store';
import { useSelector } from 'react-redux';

const { useForm } = Form;

function DiaForm(props: {
  visible: boolean;
  id: number;
  onClose: () => void;
  afterSave: () => void;
}) {
  let { visible } = props;
  const [form] = useForm<any>();
  const userInfo = useSelector((state: GlobalState) => state.userInfo);

  useEffect(() => {
    form.resetFields();
    return;
  }, [visible]);

  return (
    <Modal
      title={'重置密码'}
      visible={visible}
      onCancel={() => {
        visible = false;
        props.onClose();
      }}
      onOk={async () => {
        visible = false;
        const values = await form.validate();
        RePassword(props.id, values.password, values.newPassword).then(
          (res) => {
            props.afterSave();
          }
        );
      }}
    >
      <Form form={form} labelAlign="left">
        {props.id != userInfo.id &&
        authentication(
          {
            requiredPermissions: [
              { resource: 'userInfo', actions: ['rePassword'] },
            ],
          },
          userInfo.permissions
        ) ? (
          <></>
        ) : (
          <Form.Item
            label="原密码"
            field="password"
            rules={[
              { required: true, message: '请输入原密码' },
              { minLength: 8, message: '密码长度不应少于8位' },
            ]}
          >
            <Input.Password placeholder="请输入原密码" type="password" />
          </Form.Item>
        )}
        <Form.Item
          label="新密码"
          field="newPassword"
          rules={[
            { required: true, message: '请输入新密码' },
            { minLength: 8, message: '密码长度不应少于8位' },
          ]}
        >
          <Input.Password placeholder="请输入新密码" />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default DiaForm;
