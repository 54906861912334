import React, { useEffect } from 'react';
import Footer from '@/components/Footer';
// import Logo from '@/assets/logo.svg';
import LoginForm from './form';
import LoginBanner from './banner';
import styles from './style/index.module.less';
import qs from 'querystring';

function Login(props) {
  const { search } = props.location;
  const result = qs.parse(search.slice(1));
  const { href } = result;
  useEffect(() => {
    document.body.setAttribute('arco-theme', 'light');
  }, []);

  let hrefReal = '';
  if (href) {
    hrefReal = decodeURIComponent(href as string);
  }

  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        {/* <Logo /> */}
        <div className={styles['logo-text']}>ISH - WMS</div>
      </div>
      <div className={styles.banner}>
        <div className={styles['banner-inner']}>
          <LoginBanner />
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles['content-inner']}>
          <LoginForm href={hrefReal} />
        </div>
        <div className={styles.footer}>
          <Footer />
        </div>
      </div>
    </div>
  );
}
Login.displayName = 'LoginPage';

export default Login;
