export default function getRFC(time: string) {
	if (!time || time == "") { return undefined }
	const date = new Date(time)
	return date.toISOString()
	// const y = date.getFullYear()
	// const m = date.getMonth() + 1 < 10 ? '0' + (new Date().getMonth() + 1) : (new Date().getMonth() + 1)
	// const d = date.getDate() < 10 ? '0' + new Date().getDate() : new Date().getDate()
	// const hh = date.getHours() < 10 ? '0' + new Date().getHours() : new Date().getHours();
	// const mm = date.getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes()
	// const ss = date.getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds()
	// const endDate = y + '-' + m + '-' + d + ' ' + hh + ':' + mm + ':' + ss
	// return endDate.replace(/\s+/g, 'T') + '+08:00'
}